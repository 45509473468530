import Vue from 'vue';
import AbstractModule from '~/app/core/store/modules/AbstractModule';
import { Module, Action, Mutation } from 'vuex-module-decorators';
import { createHotel, HotelModel } from '~/utils/hotel';
import { GQLRootQuery } from '~/GqlTypes';
import gql from 'graphql-tag';
import push from '~/utils/dataLayer';
import { defaultGtmFormat } from '~/utils/dateTime';
import { format } from '~/utils/date-fns';

const PROMO_HOTELS_QUERY = gql`
  query {
    hotels {
      promoted {
        area {
          destination {
            canonicalSlug
            id
            name
            priceFlyTicket(currencyCode: "CZK") {
              amount
            }
          }
          id
          priceTransfer(currencyCode: "CZK") {
            amount
          }
        }
        beaches {
          id
          nextToHotel
        }
        canonicalSlug
        distanceToBeach
        hotelType {
          stars
        }
        id
        listImage {
          src
          srcset
          description
        }
        name
        promoPackage {
          id
          name
          numberOfNights
          validFrom
          validTo
          board {
            name
          }
          priceAccommodation(currencyCode: "CZK") {
            amount
          }
          priceFlyTicket(currencyCode: "CZK") {
            amount
          }
          priceTotal(currencyCode: "CZK") {
            amount
          }
          priceTransfer(currencyCode: "CZK") {
            amount
          }
        }
        rooms {
          area
          occupancies {
            adult
            child
          }
        }
      }
    }
  }
`;

const HOTEL_TIPS_QUERY = gql`
  query {
    hotels {
      carousel {
        canonicalSlug
        hotelType {
          stars
        }
        id
        featuredImage {
          src
          srcset
          description
        }
        area {
          destination {
            name
          }
        }
        name
        promoPackage {
          id
          name
          numberOfNights
          validFrom
          validTo
          board {
            name
          }
          priceAccommodation(currencyCode: "CZK") {
            amount
          }
          priceFlyTicket(currencyCode: "CZK") {
            amount
          }
          priceTotal(currencyCode: "CZK") {
            amount
          }
          priceTransfer(currencyCode: "CZK") {
            amount
          }
        }
      }
    }
  }
`;

export interface SetPromoHotelsCommit {
  hotels: HotelModel[];
}

interface SetHotelTipsCommit {
  hotels: HotelModel[];
}

@Module({
  name: 'Promotions',
  stateFactory: true,
  namespaced: true,
})
export default class Promotions extends AbstractModule {
  protected rawPromoHotels: { [key: string]: HotelModel } = {};

  protected rawHotelTips: { [key: string]: HotelModel } = {};

  public get promoHotels(): HotelModel[] {
    const promoHotels: HotelModel[] = [];
    for (const promoHotelsKey in this.rawPromoHotels) {
      if (this.rawPromoHotels.hasOwnProperty(promoHotelsKey)) {
        promoHotels.push(this.rawPromoHotels[promoHotelsKey]);
      }
    }

    return promoHotels;
  }

  public get hotelTips(): HotelModel[] {
    const hotelTips: HotelModel[] = [];
    for (const hotelTipsKey in this.rawHotelTips) {
      if (this.rawHotelTips.hasOwnProperty(hotelTipsKey)) {
        hotelTips.push(this.rawHotelTips[hotelTipsKey]);
      }
    }

    return hotelTips;
  }

  @Action({ commit: 'setPromoHotels', rawError: true })
  public loadPromoHotels(): Promise<SetPromoHotelsCommit> {
    return this.apollo
      .query<GQLRootQuery>({
        query: PROMO_HOTELS_QUERY,
      })
      .then((result) => {
        return {
          hotels: result.data.hotels.promoted.map((hotel) =>
            createHotel(hotel)
          ),
        };
      });
  }

  @Action({ commit: 'setHotelTips', rawError: true })
  public loadHotelTips(): Promise<SetHotelTipsCommit> {
    return this.apollo
      .query<GQLRootQuery>({
        query: HOTEL_TIPS_QUERY,
      })
      .then((result) => {
        return {
          hotels: result.data.hotels.carousel.map((hotel) =>
            createHotel(hotel)
          ),
        };
      });
  }

  @Mutation
  protected setPromoHotels(data: SetPromoHotelsCommit) {
    const hotelGtmData = [];

    for (const hotel of data.hotels) {
      hotelGtmData.push({
        item_id: hotel.id,
        item_name: hotel.name,
        item_category: hotel.destination?.name,
        price: hotel.promoPackage?.price
          ? hotel.promoPackage.price * 2
          : undefined,
        nights: hotel.promoPackage?.numberOfNights,
        meals: hotel.promoPackage?.board || hotel.boardNames.join(', '),
        start_date:
          hotel.promoPackage?.from &&
          format(hotel.promoPackage.from, defaultGtmFormat),
        end_date:
          hotel.promoPackage?.to &&
          format(hotel.promoPackage.to, defaultGtmFormat),
        base_price: hotel.promoPackage?.price,
      });
      Vue.set(this.rawPromoHotels, hotel.id, hotel);
    }

    if (hotelGtmData.length > 0) {
      push({
        event: 'view_item_list',
        ecommerce: {
          currency: 'CZK',
          item_list_name: 'Promo hotels',
          items: hotelGtmData,
        },
        _clear: true,
      });
    }
  }

  @Mutation
  protected setHotelTips(data: SetHotelTipsCommit) {
    const hotelGtmData = [];

    for (const hotel of data.hotels) {
      hotelGtmData.push({
        item_id: hotel.id,
        item_name: hotel.name,
        item_category: hotel.destination?.name,
        price: hotel.promoPackage?.price
          ? hotel.promoPackage.price * 2
          : undefined,
        nights: hotel.promoPackage?.numberOfNights,
        meals: hotel.promoPackage?.board || hotel.boardNames.join(', '),
        start_date:
          hotel.promoPackage?.from &&
          format(hotel.promoPackage.from, defaultGtmFormat),
        end_date:
          hotel.promoPackage?.to &&
          format(hotel.promoPackage.to, defaultGtmFormat),
        base_price: hotel.promoPackage?.price,
      });
      Vue.set(this.rawHotelTips, hotel.id, hotel);
    }

    if (hotelGtmData.length > 0) {
      push({
        event: 'view_item_list',
        ecommerce: {
          currency: 'CZK',
          item_list_name: 'Hotel tips',
          items: hotelGtmData,
        },
        _clear: true,
      });
    }
  }
}
