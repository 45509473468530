import 'intersection-observer';
import { createApp } from '~/app/main';
import Visitor from './app/core/store/modules/Visitor';
import { getModule } from 'vuex-module-decorators';

// client-specific bootstrapping logic...

const { app, router, store } = createApp();

// @ts-ignore
if (window.__INITIAL_STATE__) {
  // @ts-ignore
  store.replaceState(window.__INITIAL_STATE__);
}

const cookieSettings = getModule(Visitor, store);
cookieSettings.loadGTM();

router.onReady(() => {
  app.$mount('#app');
});

if ((module as any).hot) {
  (module as any).hot.accept();
}
