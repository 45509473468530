import { Component, Mixins, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { getRouteNameWithoutLang } from '~/app/core/router';

import DestinationSearch from '~/app/core/store/modules/DestinationSearch';
import Search from '~/app/core/store/modules/Search';
import Header from '~/components/templates/search/Header';
import SearchResults from '~/components/templates/search/Results';
import HeadManagement from '~/mixins/HeadManagement';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { DestinationModel } from '~/utils/destination';
import { parse } from '~/utils/date-fns';
import defaultFilterState from '~/utils/defaultFilterState';
import push from '~/utils/dataLayer';

@Component
export default class extends Mixins<PrefetchComponent>(
  HeadManagement,
  Prefetch
) {
  protected get destinationStore(): DestinationSearch {
    return getModule(DestinationSearch, this.$store);
  }

  protected get searchStore(): Search {
    return getModule(Search, this.$store);
  }

  protected get destination(): DestinationModel | null {
    return this.destinationStore.destination;
  }

  public get seoMeta(): string {
    const metas: { name?: string; property?: string; content: string }[] = [];

    let title = 'Vyhledávání';
    if (this.destination) {
      title = this.destination.name;
    }

    title += ' | CK Elixír Tours';

    metas.push({
      name: 'title',
      content: title,
    });
    metas.push({
      name: 'twitter:title',
      content: title,
    });
    metas.push({
      property: 'og:title',
      content: title,
    });

    if (this.destination) {
      metas.push({
        name: 'description',
        content: this.destination.excerpt,
      });
      metas.push({
        name: 'twitter:description',
        content: this.destination.excerpt,
      });
      metas.push({
        property: 'og:description',
        content: this.destination.excerpt,
      });
    }

    return metas
      .map((meta) => {
        return `<meta${meta.name ? ` name="${meta.name}"` : ''}${
          meta.property ? ` property="${meta.property}"` : ''
        } content="${meta.content}">`;
      })
      .join('');
  }

  public title() {
    return '';
  }

  public prefetch() {
    return this.loadSearch();
  }

  public mounted() {
    push({
      page: {
        content_type: 'search',
        currency: 'CZK',
      },
    });
  }

  public render() {
    return (
      <div>
        <Header destination={this.destination} />
        <SearchResults
          loadingDestination={this.destinationStore.loading}
          destination={this.destination}
        />
      </div>
    );
  }

  public beforeDestroy() {
    const destinationStore =
      this.destinationStore || getModule(DestinationSearch, this.$store);
    destinationStore.setDestination({
      destination: null,
      path: null,
    });
  }

  @Watch('$route', { deep: true })
  protected loadSearch() {
    const destinationStore =
      this.destinationStore || getModule(DestinationSearch, this.$store);
    const searchStore = this.searchStore || getModule(Search, this.$store);

    let maybeDestination: string | null = null;

    const routeName =
      this.$route.name && getRouteNameWithoutLang(this.$route.name);
    const isDestination = routeName === this.$t('routes.:destination.name');
    const isFulltext = routeName === this.$t('routes.search/:fulltext.name');

    if (isFulltext) {
      maybeDestination = this.$route.params.fulltext;
    } else if (isDestination) {
      maybeDestination = this.$route.params.destination;
    }

    if (maybeDestination) {
      return destinationStore
        .loadDestinationByPath({
          path: maybeDestination,
        })
        .then(() => {
          if (!this.destination && maybeDestination) {
            return searchStore.fulltextSearch({
              term: maybeDestination,
            });
          }
        });
    }

    destinationStore.setDestination({
      destination: null,
      path: null,
    });

    const searchInput = {
      date: {
        from: parse(defaultFilterState.start),
        to: parse(defaultFilterState.end),
      },
      destinations: [],
      numberOfNights: {
        max: defaultFilterState.numberOfNightsMax,
        min: defaultFilterState.numberOfNightsMin,
      },
    };

    return searchStore.search(searchInput);
  }
}
