import appEnv from '~/app/core/appEnv';

const src = '//www.googletagmanager.com/gtm.js?id=';

export default async function(container?: string): Promise<void> {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  return new Promise((resolve, reject) => {
    const lastScript = document.getElementsByTagName('script');
    if (lastScript.length < 1) {
      reject(new Error('GTM script failed to load'));
      return;
    }

    const gtmScript = document.createElement('script');
    gtmScript.async = true;
    gtmScript.src = `${src}${container || appEnv.GTM_CONTAINER}`;
    gtmScript.onload = () => {
      resolve();
      return;
    };

    gtmScript.onerror = () => {
      reject(new Error('GTM script failed to load'));
      return;
    };
    const lastScriptElement = lastScript[lastScript.length - 1];
    if (!lastScriptElement || !lastScriptElement.parentNode) {
      reject(new Error('GTM script failed to load'));
      return;
    }
    lastScriptElement.parentNode.insertBefore(gtmScript, lastScriptElement);
  });
}
