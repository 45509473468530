import { Component, Mixins } from 'vue-property-decorator';

import headerBg from '~/assets/images/header-bg-default.jpg';
import Header from '~/components/templates/Header';
import HeadManagement from '~/mixins/HeadManagement';
import voucher1 from '~/assets/voucher/voucher-1.png';
import voucher1Pdf from '~/assets/voucher/voucher-1.pdf';
import voucher2 from '~/assets/voucher/voucher-2.png';
import voucher2Pdf from '~/assets/voucher/voucher-2.pdf';
import voucher3 from '~/assets/voucher/voucher-3.png';
import voucher3Pdf from '~/assets/voucher/voucher-3.pdf';
import push from '~/utils/dataLayer';

interface Voucher {
  imageSrc: string;
  downloadName: string;
  downloadSrc: string;
}

const downloadVoucher = async (url: string, name: string) => {
  const a = document.createElement('a');

  try {
    const res = await fetch(url);
    const b = await res.blob();
    const objUrl = window.URL.createObjectURL(b);
    a.href = objUrl;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(objUrl);
  } catch (e) {
    a.href = url;
    a.click();
  }
};
const vouchers: Voucher[] = [
  {
    imageSrc: voucher1,
    downloadName: 'elixir-tours-poukaz-1.pdf',
    downloadSrc: voucher1Pdf,
  },
  {
    imageSrc: voucher2,
    downloadName: 'elixir-tours-poukaz-2.pdf',
    downloadSrc: voucher2Pdf,
  },
  {
    imageSrc: voucher3,
    downloadName: 'elixir-tours-poukaz-3.pdf',
    downloadSrc: voucher3Pdf,
  },
];

@Component
export default class GiftVoucher extends Mixins(HeadManagement) {
  public title() {
    return this.$t('app.giftVoucher.pageTitle');
  }

  public mounted() {
    push({
      page: {
        content_type: 'other',
        currency: 'CZK',
      },
    });
  }

  public render() {
    // Change colors based on the breakpoint
    const stripeColor =
      ['lg', 'xl'].indexOf(this.$vuetify.breakpoint.name.toString()) > -1
        ? 'rgba(255, 255, 255, 0.8)'
        : 'rgba(198, 197, 222, 0.9)';

    return (
      <div>
        <background-wrapper imgUrl={headerBg} class='header-bg-z-index'>
          <background-wrapper stripeColor={stripeColor}>
            <v-container class='px-0 pt-0'>
              <Header />
            </v-container>
          </background-wrapper>
        </background-wrapper>
        <v-container class='custom-content'>
          <v-card>
            <v-card-title>Dárkové poukazy Elixír tours</v-card-title>
            <v-card-text>
              <strong>
                Darujte víc než jen dárek – darujte nezapomenutelný zážitek.
              </strong>
              <p>
                Poukaz si můžete pořídit už dnes, ale zaplatit až později. A
                pokud vám na poslední chvíli chybí dárek pod stromeček, žádný
                problém – poukaz si můžete stáhnout klidně i na Štědrý večer.
                Poukazy nejsou jen vánočním dárkem – můžete je darovat kdykoli
                během roku k jakékoli příležitosti, ať už jde o narozeniny,
                výročí, nebo jen jako milé překvapení pro vaše blízké. Napište
                částku, jméno vybrané destinace či hotelu, anebo jen prostě
                poukaz na (exotickou) dovolenou.
              </p>
              <p>
                Darujte svým blízkým chvíle klidu, radosti a objevování nových
                míst. Ať už jde o svatební cestu, rodinnou dovolenou, nebo únik
                z každodenního shonu, věnujete jim cestu do exotického ráje a
                vzácné okamžiky pohody a štěstí, které si uchovají navždy.
              </p>

              <h2>Jak to funguje?</h2>
              <h3>1. Stáhněte si poukaz</h3>
              <p>
                Vyberte si z našich dárkových poukazů, stáhněte si ho zdarma,
                napište věnování a darujte.
              </p>

              <h3>2. Platba za poukaz</h3>
              <p>
                Po stažení poukazu odešlete částku dle zvolené hodnoty poukazu
                na náš bankovní účet:
              </p>
              <p>
                <strong>Číslo účtu:</strong> KB 107-9161320217/0100
              </p>
              <p>
                <strong>Variabilní symbol:</strong> Datum zakoupení (ve formátu
                den měsíc rok např. 05122024)
              </p>
              <p>
                <strong>Do zprávy pro příjemce:</strong> Uveďte jméno, pod
                kterým chcete poukaz registrovat.
              </p>

              <h3>3. Potvrzení platby</h3>
              <p>
                Jakmile obdržíme platbu, zašleme vám potvrzovací e-mail. V něm
                bude uvedeno, že poukaz je aktivní a připraven k použití.
              </p>

              <h3>4. Uplatnění poukazu</h3>
              <p>
                Obdarovaný může poukaz uplatnit při rezervaci zájezdu. Stačí při
                rezervaci uvést své jméno a datum zakoupení, které byly uvedeny
                při platbě.
              </p>

              <h2>Podmínky použití</h2>

              <ul>
                <li>Poukaz je platný 12 měsíců od data potvrzení platby.</li>
                <li>Lze ho využít na jakýkoliv zájezd z naší nabídky.</li>
                <li>Poukaz je přenosný – můžete ho darovat komukoli.</li>
              </ul>

              <br />

              <p>Elixír tours - dovolená, která vám zůstane v srdci. </p>
            </v-card-text>
          </v-card>

          <section>
            <v-row>
              {vouchers.map((voucher) => (
                <v-col cols='4' sm='12' md='4'>
                  <a
                    onClick={(e: any) => {
                      e.preventDefault();
                      downloadVoucher(
                        voucher.downloadSrc,
                        voucher.downloadName
                      );
                    }}
                  >
                    <v-img src={voucher.imageSrc} alt={voucher.downloadName} />
                  </a>
                </v-col>
              ))}
            </v-row>
          </section>
        </v-container>
      </div>
    );
  }
}
