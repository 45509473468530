import { Component, Mixins } from 'vue-property-decorator';

import galleryItem01 from '~/assets/images/weddings/mauricius/01.jpg';
import galleryItem02 from '~/assets/images/weddings/mauricius/02.jpg';
import galleryItem03 from '~/assets/images/weddings/mauricius/03.jpg';
import galleryItem04 from '~/assets/images/weddings/mauricius/04.jpg';
import galleryItem05 from '~/assets/images/weddings/mauricius/05.jpg';
import GalleryRow from '~/components/organisms/GalleryRow';
import HeadManagement from '~/mixins/HeadManagement';
import push from '~/utils/dataLayer';
import { ImageModel } from '~/utils/image';

const gallery: ImageModel[] = [
  galleryItem01,
  galleryItem02,
  galleryItem03,
  galleryItem04,
  galleryItem05,
].map((item) => {
  return {
    src: item,
    srcset: '',
    rank: 0,
    description: '',
  };
});

@Component
export default class Mauricius extends Mixins(HeadManagement) {
  public mounted() {
    push({
      page: {
        content_type: 'other',
        currency: 'CZK',
      },
    });
  }

  public render() {
    return (
      <v-container class='custom-content'>
        <h1 class='primary--text elix-section-title'>Svatba na Mauriciu</h1>
        <GalleryRow items={gallery} />
        <section>
          <h2 class='title'>Proč mít svatbu na Mauriciu?</h2>
          <p>
            Svatba na Mauriciu je velmi oblíbená. Je to díky skvělému servisu
            poskytovanému všude na ostrově, ať už si vyberete jakoukoliv
            kategorii ubytování. Mauricius je cenově velmi příznivý. Všechny
            hotely jsme osobně navštívily, abychom mohly nejen dokonale
            naplánovat Vaši svatbu, ale doporučit i to nejlepší zázemí pro Vaši
            dovolenou. Na Mauriciu je po celý rok krásné počasí, nejvíce horkých
            slunečních paprsků sem dopadá od listopadu do června.
          </p>
          <h2 class='title'>Je svatba na Mauriciu uznávaná českými úřady?</h2>
          <p>
            Ano, svatba na Mauriciu je právoplatnou v ČR. A jistě pro Vás bude
            příjemné, že nemusíte obcházet žádné úřady. Vše za Vás zařídíme my,
            a Vy si tak můžete svůj svatební den a dovolenou naplno užít.
            Neumíte-li cizí jazyk, nic se neděje, bude tam s Vámi náš tlumočník
            (na vyžádání). Den před konáním mauricijské svatby je třeba kvůli
            její platnosti navštívit státní úřad. I tady budeme s Vámi. Náš
            doprovod Vás vyzvedne v hotelu, na místě s Vámi vše vyřídí a zase
            vás odveze zpět do hotelu.
          </p>
          <h2 class='title'>Jaký hotel si pro svatbu vybrat?</h2>
          <p>
            Svatbu či svatební cestu pro Vás zorganizujeme ve Vámi vybraném
            hotelu. Nebudete-li si jisti výběrem, rády Vám s ním pomůžeme.
            Všechny hotely na Mauriciu jsou osobně prověřily a doporučíme Vám
            jen hotel, kde jsme byly maximálně spokojeny. Svěřte nám organizaci
            svatby i líbánek a nebudete litovat. I pro Vás se stane Mauricius
            srdeční záležitostí!
          </p>
          <h2 class='title'>Kdy je třeba svatbu naplánovat?</h2>
          <p>
            Kontaktujte nás minimálně dva měsíce předem. Ideálně ale půl roku
            dopředu, ať máme čas naplánovat každičký detail. Pokud nás
            kontaktujete brzy, svatba i dovolená bude cenově výhodnější.
          </p>
        </section>
        <section>
          <p>
            Tak, už se vidíte v bílém na krásné pláži s Indickým oceánem v
            pozadí? Určitě Vám to bude moc slušet.
          </p>
          <p>
            Ozvěte se nám. Všechno prodiskutujeme a naplánujeme. Těšíme se na
            Vás.
          </p>
          <p>Vaše Elixírky</p>
        </section>
      </v-container>
    );
  }
}
