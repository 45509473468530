import AbstractModule from '~/app/core/store/modules/AbstractModule';
import { Module, Action, Mutation } from 'vuex-module-decorators';
import gtm from '~/utils/gtm';
import CookieConsent from 'vanilla-cookieconsent';
import {
  CookieConsentCategory,
  CookieConsentService,
} from '~/components/organisms/CookieBar';

@Module({
  name: 'Visitor',
  stateFactory: true,
  namespaced: true,
})
export default class Visitor extends AbstractModule {
  protected gtmLoaded: boolean = false;
  protected gtmLoading: boolean = false;

  @Action({ rawError: true })
  public async handleCookieChange(
    cookieConsent: typeof CookieConsent
  ): Promise<void> {
    if (!this.gtmLoaded || !this.gtmLoading) {
      this.loadGTM();
    }
    const updateConsent = {
      [CookieConsentService.AD_STORAGE]: cookieConsent.acceptedService(
        CookieConsentService.AD_STORAGE,
        CookieConsentCategory.ADVERTISEMENT
      )
        ? 'granted'
        : 'denied',
      [CookieConsentService.AD_USER_DATA]: cookieConsent.acceptedService(
        CookieConsentService.AD_USER_DATA,
        CookieConsentCategory.ADVERTISEMENT
      )
        ? 'granted'
        : 'denied',
      [CookieConsentService.AD_PERSONALIZATION]: cookieConsent.acceptedService(
        CookieConsentService.AD_PERSONALIZATION,
        CookieConsentCategory.ADVERTISEMENT
      )
        ? 'granted'
        : 'denied',
      [CookieConsentService.ANALYTICS_STORAGE]: cookieConsent.acceptedService(
        CookieConsentService.ANALYTICS_STORAGE,
        CookieConsentCategory.ANALYTICS
      )
        ? 'granted'
        : 'denied',
      [CookieConsentService.FUNCTIONALITY_STORAGE]: cookieConsent.acceptedService(
        CookieConsentService.FUNCTIONALITY_STORAGE,
        CookieConsentCategory.FUNCTIONALITY
      )
        ? 'granted'
        : 'denied',
      [CookieConsentService.PERSONALIZATION_STORAGE]: cookieConsent.acceptedService(
        CookieConsentService.PERSONALIZATION_STORAGE,
        CookieConsentCategory.FUNCTIONALITY
      )
        ? 'granted'
        : 'denied',
      [CookieConsentService.SECURITY_STORAGE]: cookieConsent.acceptedService(
        CookieConsentService.SECURITY_STORAGE,
        CookieConsentCategory.SECURITY
      )
        ? 'granted'
        : 'denied',
    };

    window.gtag('consent', 'update', updateConsent);

    return Promise.resolve();
  }

  @Action({ rawError: true })
  public async loadGTM(): Promise<void> {
    if (!this.gtmLoaded && !this.gtmLoading) {
      this.setGTMLoading(true);
      return gtm()
        .then(() => {
          this.setGTMLoaded(true);
        })
        .finally(() => {
          this.setGTMLoading(false);
        });
    }
    return Promise.resolve();
  }

  @Mutation
  protected setGTMLoaded(state: boolean) {
    this.gtmLoaded = state;
  }

  @Mutation
  protected setGTMLoading(state: boolean) {
    this.gtmLoading = state;
  }
}
