import { Component, Mixins } from 'vue-property-decorator';

import galleryItem01 from '~/assets/images/weddings/seychely/01.jpg';
import galleryItem02 from '~/assets/images/weddings/seychely/02.jpg';
import galleryItem03 from '~/assets/images/weddings/seychely/03.jpg';
import galleryItem04 from '~/assets/images/weddings/seychely/04.jpg';
import galleryItem05 from '~/assets/images/weddings/seychely/05.jpg';
import GalleryRow from '~/components/organisms/GalleryRow';
import HeadManagement from '~/mixins/HeadManagement';
import push from '~/utils/dataLayer';
import { ImageModel } from '~/utils/image';

const gallery: ImageModel[] = [
  galleryItem01,
  galleryItem02,
  galleryItem03,
  galleryItem04,
  galleryItem05,
].map((item) => {
  return {
    src: item,
    srcset: '',
    rank: 0,
    description: '',
  };
});

@Component
export default class Seychely extends Mixins(HeadManagement) {
  public mounted() {
    push({
      page: {
        content_type: 'other',
        currency: 'CZK',
      },
    });
  }

  public render() {
    return (
      <v-container class='custom-content'>
        <h1 class='primary--text elix-section-title'>Svatba na Seychelách</h1>
        <GalleryRow items={gallery} />
        <section>
          <h2 class='title'>Proč mít svatbu na Seychelách?</h2>
          <p>
            Na seychelských ostrovech je nádherná příroda a počty turistů jsou
            regulované. To dodává Seychelám na jedinečnosti. Celý rok je tu
            slunečno a teploty se pohybují celý rok kolem 26° C, tedy příjemné
            teplo, nikoli vedro. Navíc, 115 ostrovů nabízí neskutečný výběr
            lokalit. Je jen na Vás, zda si vyberete svatbu v ráji na hlavním
            ostrově Mahé, na romantickém ostrově La Digue, na jedné z{' '}
            <a href='https://blog.elixirtours.cz/seychely/prochazka-rajem-jmenem-seychely/'>
              nejkrásnějších pláží na světě
            </a>{' '}
            Anse Source d’Argent, nebo na jednom z nádherných neobydlených
            ostrovů.
          </p>
          <h2 class='title'>Je svatba na Seychelách uznávaná českými úřady?</h2>
          <p>
            Ano, svatba na Seychelách je právoplatnou v ČR. A jistě pro Vás bude
            příjemné, že nemusíte obcházet žádné úřady. Vše za Vás zařídíme my,
            a Vy si tak můžete svůj svatební den a dovolenou naplno užít.
            Neumíte-li cizí jazyk, nic se neděje, bude tam s Vámi náš tlumočník
            (na vyžádání).
          </p>
          <h2 class='title'>Jaký hotel si pro svatbu vybrat?</h2>
          <p>
            Svatbu či svatební cestu pro Vás zorganizujeme ve Vámi vybraném
            hotelu. Nebudete-li si jisti výběrem, rády Vám s ním pomůžeme.
            Seychely jsou naším druhým domovem a doporučíme Vám jen hotel, kde
            jsme byly a apartmány a nabízené služby jsme osobně prověřily.
            Svěřte nám organizaci svatby i líbánek a nebudete litovat. Z
            dovolené na Seychelách se nebudete chtít vrátit!
          </p>
          <h2 class='title'>Kdy je třeba svatbu naplánovat?</h2>
          <p>
            Kontaktujte nás minimálně dva měsíce předem. Ideálně ale půl roku
            dopředu, ať máme čas naplánovat každičký detail. Pokud nás
            kontaktujete brzy, svatba i dovolená bude cenově výhodnější.
          </p>
        </section>
        <section>
          <p>
            Tak, už se vidíte v bílém na krásné pláži s Indickým oceánem v
            pozadí? Určitě Vám to bude moc slušet.
          </p>
          <p>
            Ozvěte se nám. Všechno prodiskutujeme a naplánujeme. Těšíme se na
            Vás.
          </p>
          <p>Vaše Elixírky</p>
        </section>
      </v-container>
    );
  }
}
