import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { getLocalizedRoute, routes } from '~/app/core/router/routes';

import style from 'vanilla-cookieconsent/dist/cookieconsent.css';
import Visitor from '~/app/core/store/modules/Visitor';
import { getModule } from 'vuex-module-decorators';
import isEnumGenerator from '~/utils/isEnumGenerator';

export enum CookieConsentCategory {
  ADVERTISEMENT = 'advertisement',
  ANALYTICS = 'analytics',
  FUNCTIONALITY = 'functionality',
  NECESSARY = 'necessary',
  SECURITY = 'security',
}

export enum CookieConsentService {
  AD_STORAGE = 'ad_storage',
  AD_USER_DATA = 'ad_user_data',
  AD_PERSONALIZATION = 'ad_personalization',
  ANALYTICS_STORAGE = 'analytics_storage',
  FUNCTIONALITY_STORAGE = 'functionality_storage',
  PERSONALIZATION_STORAGE = 'personalization_storage',
  SECURITY_STORAGE = 'security_storage',
}

export const isCookieConsentCategory = isEnumGenerator(CookieConsentCategory);

@Component({ style })
export default class extends VueComponent<{}> {
  protected get visitorModule(): Visitor {
    return getModule(Visitor, this.$store);
  }

  public mounted() {
    this.$cookieConsent.run({
      guiOptions: {
        consentModal: {
          layout: 'bar',
          position: 'bottom',
        },
      },
      categories: {
        [CookieConsentCategory.NECESSARY]: {
          enabled: true,
          readOnly: true,
        },
        [CookieConsentCategory.ANALYTICS]: {
          autoClear: {
            cookies: [
              {
                name: /^_ga/,
              },
              {
                name: '_gid',
              },
            ],
          },
          services: {
            [CookieConsentService.ANALYTICS_STORAGE]: {
              label: this.$t(
                'app.cookies.services.analyticsStorage'
              ).toString(),
            },
          },
        },
        [CookieConsentCategory.ADVERTISEMENT]: {
          services: {
            [CookieConsentService.AD_STORAGE]: {
              label: this.$t('app.cookies.services.adStorage').toString(),
            },
            [CookieConsentService.AD_USER_DATA]: {
              label: this.$t('app.cookies.services.adUserData').toString(),
            },
            [CookieConsentService.AD_PERSONALIZATION]: {
              label: this.$t(
                'app.cookies.services.adPersonalisation'
              ).toString(),
            },
          },
        },
        [CookieConsentCategory.FUNCTIONALITY]: {
          services: {
            [CookieConsentService.FUNCTIONALITY_STORAGE]: {
              label: this.$t(
                'app.cookies.services.functionalityStorage'
              ).toString(),
            },
            [CookieConsentService.PERSONALIZATION_STORAGE]: {
              label: this.$t(
                'app.cookies.services.personalisationStorage'
              ).toString(),
            },
          },
        },
        [CookieConsentCategory.SECURITY]: {
          services: {
            [CookieConsentService.SECURITY_STORAGE]: {
              label: this.$t('app.cookies.services.securityStorage').toString(),
            },
          },
        },
      },
      language: {
        default: 'cs',
        translations: {
          cs: {
            consentModal: {
              title: this.$t('app.cookies.consentModal.title').toString(),
              description: this.$t(
                'app.cookies.consentModal.description'
              ).toString(),
              acceptAllBtn: this.$t(
                'app.cookies.consentModal.acceptAllBtn'
              ).toString(),
              acceptNecessaryBtn: this.$t(
                'app.cookies.consentModal.acceptNecessaryBtn'
              ).toString(),
              showPreferencesBtn: this.$t(
                'app.cookies.consentModal.showPreferencesBtn'
              ).toString(),
            },
            preferencesModal: {
              title: this.$t('app.cookies.preferencesModal.title').toString(),
              acceptAllBtn: this.$t(
                'app.cookies.preferencesModal.acceptAllBtn'
              ).toString(),
              acceptNecessaryBtn: this.$t(
                'app.cookies.preferencesModal.acceptNecessaryBtn'
              ).toString(),
              savePreferencesBtn: this.$t(
                'app.cookies.preferencesModal.savePreferencesBtn'
              ).toString(),
              closeIconLabel: this.$t(
                'app.cookies.preferencesModal.closeIconLabel'
              ).toString(),
              sections: [
                {
                  title: this.$t(
                    'app.cookies.preferencesModal.sections.title'
                  ).toString(),
                  description: this.$t(
                    'app.cookies.preferencesModal.sections.description',
                    {
                      url: this.$router.resolve(
                        getLocalizedRoute(routes.dataProtection, this.$router)
                      ).href,
                    }
                  ).toString(),
                },
                {
                  title: this.$t(
                    'app.cookies.preferencesModal.sections.necessary.title'
                  ).toString(),
                  description: this.$t(
                    'app.cookies.preferencesModal.sections.necessary.description'
                  ).toString(),
                  linkedCategory: CookieConsentCategory.NECESSARY,
                },
                {
                  title: this.$t(
                    'app.cookies.preferencesModal.sections.analytics.title'
                  ).toString(),
                  description: this.$t(
                    'app.cookies.preferencesModal.sections.analytics.description'
                  ).toString(),
                  linkedCategory: CookieConsentCategory.ANALYTICS,
                  cookieTable: {
                    headers: {
                      name: this.$t(
                        'app.cookies.preferencesModal.sections.analytics.cookieTable.headers.name'
                      ).toString(),
                      domain: this.$t(
                        'app.cookies.preferencesModal.sections.analytics.cookieTable.headers.domain'
                      ).toString(),
                      description: this.$t(
                        'app.cookies.preferencesModal.sections.analytics.cookieTable.headers.description'
                      ).toString(),
                      expiration: this.$t(
                        'app.cookies.preferencesModal.sections.analytics.cookieTable.headers.expiration'
                      ).toString(),
                    },
                    body: [
                      {
                        name: '_ga',
                        domain: this.$t(
                          'app.cookies.preferencesModal.sections.analytics.cookieTable.body.ga.expiration'
                        ).toString(),
                        description: this.$t(
                          'app.cookies.preferencesModal.sections.analytics.cookieTable.body.ga.expiration'
                        ).toString(),
                        expiration: this.$t(
                          'app.cookies.preferencesModal.sections.analytics.cookieTable.body.ga.expiration'
                        ).toString(),
                      },
                      {
                        name: '_gid',
                        domain: this.$t(
                          'app.cookies.preferencesModal.sections.analytics.cookieTable.body.gid.expiration'
                        ).toString(),
                        description: this.$t(
                          'app.cookies.preferencesModal.sections.analytics.cookieTable.body.gid.expiration'
                        ).toString(),
                        expiration: this.$t(
                          'app.cookies.preferencesModal.sections.analytics.cookieTable.body.gid.expiration'
                        ).toString(),
                      },
                    ],
                  },
                },
                {
                  title: this.$t(
                    'app.cookies.preferencesModal.sections.functionality.title'
                  ).toString(),
                  description: this.$t(
                    'app.cookies.preferencesModal.sections.functionality.description'
                  ).toString(),
                  linkedCategory: CookieConsentCategory.FUNCTIONALITY,
                },
                {
                  title: this.$t(
                    'app.cookies.preferencesModal.sections.security.title'
                  ).toString(),
                  description: this.$t(
                    'app.cookies.preferencesModal.sections.security.description'
                  ).toString(),
                  linkedCategory: CookieConsentCategory.SECURITY,
                },
                {
                  title: this.$t(
                    'app.cookies.preferencesModal.sections.advertisement.title'
                  ).toString(),
                  description: this.$t(
                    'app.cookies.preferencesModal.sections.advertisement.description'
                  ).toString(),
                  linkedCategory: CookieConsentCategory.ADVERTISEMENT,
                },
              ],
            },
          },
        },
      },
      onConsent: () => {
        this.visitorModule.handleCookieChange(this.$cookieConsent);
      },
      onChange: () => {
        this.visitorModule.handleCookieChange(this.$cookieConsent);
      },
      revision: 2,
    });
  }

  public render() {
    return null;
  }
}
