import { Component, Mixins } from 'vue-property-decorator';

import headerBg from '~/assets/images/header-bg-default.jpg';
import references from '~/assets/references.json';
import Header from '~/components/templates/Header';
import HeadManagement from '~/mixins/HeadManagement';
import push from '~/utils/dataLayer';
import HTMLRenderer from '~/utils/HTMLRenderer';

interface Reference {
  title: string;
  content: string;
  date: string;
}
@Component
export default class References extends Mixins(HeadManagement) {
  public title() {
    return this.$t('app.references.pageTitle');
  }

  public get seoMeta(): string {
    const metas: { name?: string; property?: string; content: string }[] = [];

    metas.push({
      name: 'title',
      content: 'Dovolená, Recenze | CK Elixír Tours',
    });
    metas.push({
      name: 'twitter:title',
      content: 'Dovolená, Recenze | CK Elixír Tours',
    });
    metas.push({
      property: 'og:title',
      content: 'Dovolená, Recenze | CK Elixír Tours',
    });

    metas.push({
      name: 'description',
      content:
        'Recenze zákazníků Elixír tours, specialisty na exotické destinace prověřené Travel specialistkami.',
    });
    metas.push({
      name: 'twitter:description',
      content:
        'Recenze zákazníků Elixír tours, specialisty na exotické destinace prověřené Travel specialistkami.',
    });
    metas.push({
      property: 'og:description',
      content:
        'Recenze zákazníků Elixír tours, specialisty na exotické destinace prověřené Travel specialistkami.',
    });

    return metas
      .map((meta) => {
        return `<meta${meta.name ? ` name="${meta.name}"` : ''}${
          meta.property ? ` property="${meta.property}"` : ''
        } content="${meta.content}">`;
      })
      .join('');
  }

  public mounted() {
    push({
      page: {
        content_type: 'other',
        currency: 'CZK',
      },
    });
  }

  public render() {
    // Change colors based on the breakpoint
    const stripeColor =
      ['lg', 'xl'].indexOf(this.$vuetify.breakpoint.name.toString()) > -1
        ? 'rgba(255, 255, 255, 0.8)'
        : 'rgba(198, 197, 222, 0.9)';

    return (
      <div>
        <background-wrapper imgUrl={headerBg} class='header-bg-z-index'>
          <background-wrapper stripeColor={stripeColor}>
            <v-container class='px-0 pt-0'>
              <Header />
            </v-container>
          </background-wrapper>
        </background-wrapper>
        <v-container class='custom-content'>
          <h1 class='elix-title primary--text text-center text-sm-left'>
            {this.$t('app.references.references')}
          </h1>
          {references.map((reference: Reference) => {
            return (
              <v-card class='my-3'>
                <v-card-title class='headline accent--text'>
                  <HTMLRenderer content={reference.title} />
                </v-card-title>
                <v-card-text>
                  <HTMLRenderer content={reference.content} />
                </v-card-text>
              </v-card>
            );
          })}
        </v-container>
      </div>
    );
  }
}
