import { BigBlockInterface, BigBlocksInterface } from 'elixir-theme';
import gql from 'graphql-tag';
import { LocaleMessage } from 'vue-i18n';
import { Component, Prop } from 'vue-property-decorator';

import { HotelModel, createBigHotelBlock } from '~/utils/hotel';
import { VueComponent } from '~/utils/vue-component';

interface List {
  buttonDisabled?: boolean;
  fetchingMore?: boolean;
  headline: LocaleMessage | null;
  hotels: HotelModel[];
  loading?: boolean;
  onLoadMore?: () => void;
}

export const HOTEL_LIST_FRAGMENT = gql`
  fragment HotelFragment on Hotel {
    area {
      destination {
        id
        name
        priceFlyTicket(currencyCode: "CZK") {
          amount
        }
      }
      id
      priceTransfer(currencyCode: "CZK") {
        amount
      }
    }
    beaches {
      id
      nextToHotel
    }
    boards {
      id
      name
    }
    canonicalSlug
    distanceToBeach
    hotelType {
      id
      stars
    }
    id
    listImage {
      src
      srcset
      description
    }
    name
    promoPackage {
      id
      name
      numberOfNights
      validFrom
      validTo
      board {
        name
      }
      priceAccommodation(currencyCode: "CZK") {
        amount
      }
      priceFlyTicket(currencyCode: "CZK") {
        amount
      }
      priceTotal(currencyCode: "CZK") {
        amount
      }
      priceTransfer(currencyCode: "CZK") {
        amount
      }
    }
    rooms {
      area
      id
      occupancies {
        adult
        child
        id
      }
    }
  }
`;

@Component
export default class extends VueComponent<List> implements List {
  @Prop({ required: true, type: Array })
  public hotels!: HotelModel[];

  @Prop({ default: null, type: String })
  public headline!: LocaleMessage | null;

  @Prop({ default: true, type: Boolean })
  public buttonDisabled!: boolean;

  @Prop({ default: false, type: Boolean })
  public loading!: boolean;

  @Prop({ default: false, type: Boolean })
  public fetchingMore!: boolean;

  protected get blocks(): BigBlockInterface[] {
    return this.hotels.map((hotel) =>
      createBigHotelBlock(hotel, this.$router, this.$i18n)
    );
  }

  public render() {
    const attrs: BigBlocksInterface = {
      blocks: this.blocks,
      header: this.headline ? this.headline.toString() : '',
      isButtonDisabled: this.buttonDisabled,
      isLoading: this.fetchingMore,
      bottomButtonText: this.$t('app.hotel.list.loadButton').toString(),
    };

    return (
      <v-container class='px-0 py-0'>
        <v-layout class='mt-0'>
          <v-flex xs12 lg9>
            {(() => {
              if (this.loading) {
                return (
                  <h3 class='primary--text mt-0 pt-0 mb-3 elix-title text-center text-lg-left'>
                    {this.headline}
                  </h3>
                );
              }
            })()}
            <v-skeleton-loader
              loading={this.loading}
              transition='scale-transition'
              type='hotel'
              types={{ hotel: 'image, article' }}
            >
              <big-blocks-right-preview
                {...{ attrs }}
                onCenterButtonClick={this.loadMore}
              />
            </v-skeleton-loader>
          </v-flex>
        </v-layout>
      </v-container>
    );
  }

  protected loadMore() {
    this.$emit('loadMore');
  }
}
