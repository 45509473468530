import { Component, Mixins } from 'vue-property-decorator';

import headerBg from '~/assets/images/header-bg-default.jpg';
import Header from '~/components/templates/Header';
import HeadManagement from '~/mixins/HeadManagement';
import push from '~/utils/dataLayer';

@Component
export default class DataProtection extends Mixins(HeadManagement) {
  public title() {
    return this.$t('app.dataProtection.pageTitle');
  }

  public mounted() {
    push({
      page: {
        content_type: 'other',
        currency: 'CZK',
      },
    });
  }

  public render() {
    // Change colors based on the breakpoint
    const stripeColor =
      ['lg', 'xl'].indexOf(this.$vuetify.breakpoint.name.toString()) > -1
        ? 'rgba(255, 255, 255, 0.8)'
        : 'rgba(198, 197, 222, 0.9)';

    return (
      <div>
        <background-wrapper imgUrl={headerBg} class='header-bg-z-index'>
          <background-wrapper stripeColor={stripeColor}>
            <v-container class='px-0 pt-0'>
              <Header />
            </v-container>
          </background-wrapper>
        </background-wrapper>
        <v-container class='custom-content'>
          <section>
            <h1 class='headline'>Souhlas se zpracováním osobních údajů</h1>
            <p>
              Tímto uděluji společnosti Elixír tours, s.r.o. se sídlem Holšická
              1327, Praha 9 – Újezd nad lesy, IČ: 29026270, zapsané v obchodním
              rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 160946,
              (dále jen „společnost Elixír tours“),{' '}
              <strong>svobodný a dobrovolný souhlas</strong> se zpracováním
              osobních údajů pro marketingové účely, tj. zejména k zasílání
              informací o produktech, službách, anketách, soutěžích a dalších
              akcích společnosti Elixír tours.
            </p>
            <p>
              V souladu se zákonem č.101/2000 Sb., o ochraně osobních údajů v
              rozsahu bude Elixír tours jako správce zpracovávat osobní údaje v
              rozsahu, v jakém byly mnou poskytnuty, zejména jméno, příjmení a
              emailová adresu, případně další informace, které o mně společnost
              Elixír tours získala.
            </p>
            <p>Souhlas uděluji na dobu 5 let od dne udělení.</p>
            <p>
              Tento souhlas mohu kdykoliv odvolat, a to i před uplynutím doby,
              na kterou byl udělen. Mohu tak učinit elektronicky prostřednictvím
              e-mailu info@elixirtours.cz nebo písemně na výše uvedené adrese
              společnosti Elixír tours. Beru na vědomí, že odvoláním souhlasu
              není dotčena zákonnost zpracování osobních údajů před odvoláním
              souhlasu.
            </p>
            <p>
              Podrobné informace o ochraně osobních údajů jsou mi k dispozici
              níže.
            </p>
          </section>
          <section>
            <h2>Informace o ochraně osobních údajů</h2>
            <p>
              <strong>Rozsah zpracování osobních údajů:</strong> Správce bude
              Vaše osobní údaje zpracovávat v rozsahu, v jakém byly Vámi
              poskytnuty, v souvislosti s objednávkou produktů a služeb správce,
              respektive uzavřenou smlouvou o zajištění zájezdu či sportovního
              pobytu mezi Vámi a poskytovatelem jako správcem, tj. zejména v
              rozsahu: jméno, příjmení, e‐mail, telefonní číslo a adresa
              bydliště či datum narození.
            </p>
            <p>
              <strong>Účel zpracování osobních údajů:</strong> Správce bude Vaše
              osobní údaje zpracovávat za účelem splnění smlouvy uzavřené mezi
              Vámi a správcem, zařazení do databáze správce a přímého
              marketingu, tedy informací o nabídce produktů a služeb správce či
              třetích osob včetně zasílání obchodních sdělení ve smyslu zákona
              č. 480/2004 Sb., o některých službách informační společnosti.
            </p>
            <p>
              <strong>Oprávnění ke zpracování osobních údajů:</strong>{' '}
              Oprávněním ke zpracování osobních údajů je tento Váš souhlas, dále
              oprávněný zájem správce (zpracování za účelem přímého marketingu)
              a plnění práv a povinností vyplývající ze závazné objednávky, na
              jejímž základě dojde k uzavření smlouvy, jakož i vlastní smlouva.
            </p>
            <p>
              <strong>Doba zpracování osobních údajů:</strong> Správce bude Vaše
              osobní údaje zpracovávat po dobu 5 let, nebude-li tento souhlas se
              zpracováním osobních údajů z Vaší strany odvolán.
            </p>
            <p>
              <strong>Odvolání souhlasu se zpracováním osobních údajů:</strong>{' '}
              Váš dobrovolně udělený souhlas se zpracováním osobních údajů
              můžete kdykoli bezplatně odvolat, a to na níže uvedených
              kontaktech správce. Odvoláním souhlasu není dotčena zákonnost
              zpracování vycházejícího ze souhlasu, který byl dán před jeho
              odvolání. Rovněž nemá vliv na zpracování osobních údajů, které
              správce zpracovává na základě jiného právního základu, než je
              souhlas (zpracování nezbytné pro splnění smlouvy, právní
              povinnosti či z jiných důvodů uvedených v platných právních
              předpisech).
            </p>
            <p>
              <strong>Přístup k osobním údajům:</strong> K Vašim osobním údajům
              bude mít přístup správce a další zpracovatelé, kteří poskytují
              vhodné záruky a jejichž zpracování je v souladu s požadavky dle
              platných právních předpisů a zajišťuje náležitou ochranu Vašich
              práv.
            </p>
            <p>
              <strong>Práva v souvislosti s ochranou osobních údajů:</strong> Ve
              vztahu k Vašim osobním údajům máte zejména následující práva:
              <ul>
                <li>a) právo na přístup k osobním údajům</li>
                <li>b) právo svůj souhlas kdykoli odvolat;</li>
                <li>c) právo osobní údaje opravit či doplnit;</li>
                <li>d) právo požadovat omezení zpracování;</li>
                <li>
                  e) právo vznést námitku či stížnost proti zpracování v
                  určitých případech;
                </li>
                <li>f) právo požadovat přenesení údajů;</li>
                <li>
                  g) právo být informován o porušení zabezpečení osobních údajů
                  v určitých případech;
                </li>
                <li>h) právo na výmaz osobních údajů v určitých případech;</li>
                <li>
                  i) další práva stanovená v zákoně o ochraně osobních údajů a v
                  obecném nařízení o ochraně osobních údajů č. 2016/679
                </li>
              </ul>
            </p>
            <p>
              <strong>Povinnost poskytnutí osobních údajů:</strong> Neexistuje
              žádná povinnost, Vaše osobní údaje poskytujete správci zcela
              dobrovolně. Pro některé služby je však poskytnutí některých
              osobních údajů vyžadováno, tzn. pokud je správci neposkytnete,
              nebude Vám umožněno tuto službu využívat.
            </p>
            <p>
              <strong>Zabezpečení osobních údajů:</strong> Veškeré osobní údaje,
              které správci poskytnete, jsou zabezpečeny standardními postupy a
              technologiemi. Správce zaručuje, že své systémy pravidelně
              kontroluje a využívá taková bezpečností opatření, která je možné
              rozumně vyžadovat, aby nedošlo k neoprávněnému přístupu k
              poskytnutým osobním údajům.
            </p>
          </section>
          <section>
            <p>
              <strong>
                Ohledně ochrany osobních údajů s námi můžete komunikovat
                prostřednictvím následujících kontaktů:
              </strong>
            </p>
            <p>
              <strong>Elektronicky na adrese:</strong> info@elixirtours.cz
            </p>
            <p>
              <strong>Telefonicky na tel. čísle:</strong> +420 603 44 77 55
            </p>
            <p>
              <strong>Písemně na adrese:</strong> Elixír tours, s.r.o. Holšická
              1327, 190 16 Praha 9
            </p>
          </section>
        </v-container>
      </div>
    );
  }
}
