import { LocaleMessages } from 'vue-i18n';

const i18n: LocaleMessages = {
  app: {
    cookies: {
      settingsButton: 'Nastavení cookies',
      consentModal: {
        title: 'Cookies',
        description:
          'Tento web používá soubory cookies 🍪. Používáním tohoto webu souhlasíte s ukládáním a používáním nezbytných souborů cookies. Zakliknutím tlačítka "Povolit vše" udělujete souhlas k ukládání a používání i dalších souborů cookies jako jsou statistické, preferenční a marketingové.',
        acceptAllBtn: 'Přijmout vše',
        acceptNecessaryBtn: 'Odmítnout vše',
        showPreferencesBtn: 'Nastavení',
      },
      preferencesModal: {
        title: 'Cookies',
        acceptAllBtn: 'Povolit vše',
        acceptNecessaryBtn: 'Zakázat vše',
        savePreferencesBtn: 'Uložit',
        closeIconLabel: 'Zavřít',
        sections: {
          title: 'Vyberte si, jaká nastavení chcete povolit.',
          description:
            '<a href="{url}" target="_blank" rel="noopener noreferrer">Více informací o cookies</a>',
          advertisement: {
            title: 'Reklama',
            description:
              'Google používá cookies pro reklamu, včetně zobrazování a vykreslování reklam, personalizace reklam (v závislosti na vašich nastaveních reklam na <a href="https://g.co/adsettings">g.co/adsettings</a>), omezení počtu zobrazení jedné reklamy uživateli, ztlumení reklam, které si přejete přestat vidět, a měření účinnosti reklam.',
          },
          analytics: {
            title: 'Analytické cookies',
            description:
              'Analytické cookies nám umožňují měření výkonu našeho webu a našich reklamních kampaní. Jejich pomocí určujeme počet návštěv a zdroje návštěv našich internetových stránek. Data získaná pomocí těchto cookies zpracováváme souhrnně, bez použití identifikátorů, které ukazují na konkrétní uživatelé našeho webu. Pokud vypnete používání analytických cookies ve vztahu k Vaší návštěvě, ztrácíme možnost analýzy výkonu a optimalizace našich opatření.',
            cookieTable: {
              headers: {
                name: 'Název',
                domain: 'Služba',
                description: 'Popis',
                expiration: 'Doba platnosti',
              },
              body: {
                ga: {
                  domain: 'Google Analytics',
                  description:
                    'Cookie nastavená službou <a href="https://business.safety.google/adscookies/">Google Analytics</a>',
                  expiration: 'Platnost 12 dní',
                },
                gid: {
                  domain: 'Google Analytics',
                  description:
                    'Cookie nastavená službou <a href="https://business.safety.google/adscookies/">Google Analytics</a>',
                  expiration: 'Relace',
                },
              },
            },
          },
          functionality: {
            title: 'Personalizované soubory cookie',
            description:
              'Používáme rovněž soubory cookie a další technologie, abychom přizpůsobili naše webové stránky potřebám a zájmům našich návštěvníků.',
          },
          necessary: {
            title: 'Technické cookies',
            description:
              'Technické cookies jsou nezbytné pro správné fungování webu a všech funkcí, které nabízí. Nepožadujeme Váš souhlas s využitím technických cookies na našem webu. Z tohoto důvodu technické cookies nemohou být individuálně deaktivovány nebo aktivovány.',
          },
          security: {
            title: 'Zabezpečení',
            description:
              'Cookies používané pro zabezpečení ověřují uživatele, zabraňují podvodům a chrání uživatele při interakci se službou.',
          },
        },
      },
      services: {
        analyticsStorage:
          'Povoluje ukládání (např. cookies) související s analytikou, např. délkou návštěvy.',
        adStorage: 'Povoluje ukládání (např. cookies) související s reklamou.',
        adUserData:
          'Nastavuje souhlas pro odesílání uživatelských údajů souvisejících s reklamou společnosti Google.',
        adPersonalisation: 'Nastavuje souhlas pro personalizovanou reklamu.',
        functionalityStorage:
          'Povoluje ukládání podporující funkčnost webu nebo aplikace, např. jazyková nastavení.',
        personalisationStorage:
          'Povoluje ukládání související s personalizací, např. doporučení videí.',
        securityStorage:
          'Povoluje ukládání související se zabezpečením, jako je ověřování, prevence podvodů a další ochrana uživatelů.',
      },
    },
  },
};

export default i18n;
