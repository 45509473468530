import { Component, Mixins } from 'vue-property-decorator';

import headerBg from '~/assets/images/header-bg-default.jpg';
import galleryItem01 from '~/assets/images/weddings/01.jpg';
import galleryItem02 from '~/assets/images/weddings/02.jpg';
import galleryItem03 from '~/assets/images/weddings/03.jpg';
import galleryItem04 from '~/assets/images/weddings/04.jpg';
import galleryItem05 from '~/assets/images/weddings/05.jpg';
import GalleryRow from '~/components/organisms/GalleryRow';
import Header from '~/components/templates/Header';
import HeadManagement from '~/mixins/HeadManagement';
import { ImageModel } from '~/utils/image';
import { getLocalizedRoute, routes } from '~/app/core/router/routes';
import WeddingForm from '~/components/templates/WeddingForm';
import push from '~/utils/dataLayer';

const gallery: ImageModel[] = [
  galleryItem01,
  galleryItem02,
  galleryItem03,
  galleryItem04,
  galleryItem05,
].map((item) => {
  return {
    src: item,
    srcset: '',
    rank: 0,
    description: '',
  };
});

@Component
export default class Weddings extends Mixins(HeadManagement) {
  public title() {
    return this.$t('app.weddings.pageTitle');
  }

  public mounted() {
    push({
      page: {
        content_type: 'lead',
        currency: 'CZK',
      },
    });
  }

  public render() {
    // Change colors based on the breakpoint
    const stripeColor =
      ['lg', 'xl'].indexOf(this.$vuetify.breakpoint.name.toString()) > -1
        ? 'rgba(255, 255, 255, 0.8)'
        : 'rgba(198, 197, 222, 0.9)';

    return (
      <div>
        <background-wrapper imgUrl={headerBg} class='header-bg-z-index'>
          <background-wrapper stripeColor={stripeColor}>
            <v-container class='px-0 pt-0'>
              <Header />
            </v-container>
          </background-wrapper>
        </background-wrapper>
        {this.renderContent()}
      </div>
    );
  }

  protected renderContent() {
    if (this.$route.meta.wedding === true) {
      return (
        <div>
          <v-container class='pt-5'>
            <v-btn
              to={getLocalizedRoute(routes.weddings, this.$router)}
              color='primary'
            >
              <v-icon left>mdi-arrow-left</v-icon>
              Zpět na svatby
            </v-btn>
          </v-container>
          <router-view />
        </div>
      );
    }

    return (
      <v-container class='custom-content'>
        <h1 class='primary--text elix-section-title'>Svatby</h1>
        <GalleryRow items={gallery} />
        <v-row justify='space-around' no-gutters class='mt-5'>
          <v-btn
            color='accent'
            to={
              getLocalizedRoute(routes.weddings, this.$router).path +
              '/seychely'
            }
          >
            Svatby na Seychelách
          </v-btn>
          <v-btn
            color='accent'
            to={
              getLocalizedRoute(routes.weddings, this.$router).path +
              '/mauricius'
            }
          >
            Svatby na Mauriciu
          </v-btn>
          <v-btn
            color='accent'
            to={
              getLocalizedRoute(routes.weddings, this.$router).path +
              '/maledivy'
            }
          >
            Svatby na Maledivách
          </v-btn>
          <v-btn
            color='accent'
            to={
              getLocalizedRoute(routes.weddings, this.$router).path +
              '/sri-lanka'
            }
          >
            Svatby na Srí Lance
          </v-btn>
        </v-row>
        <section class='text-center mt-10'>
          <p>
            <strong>Toužíte po svatbě na pláži?</strong>
          </p>
          <p>
            <strong>
              Je Vaším snem říci si své ano v exotickém ráji na Seychelách,
              Mauriciu nebo Maledivách?
            </strong>
          </p>
          <p>
            <strong>
              Chcete si slíbit věrnost za zvuku bubnů v mystické krajině Bali či
              Srí Lanky?
            </strong>
          </p>
          <p>
            <strong>
              Měli jste obřad s rodinou a teď byste si rádi užili romantické
              líbánky jen ve dvou?
            </strong>
          </p>
          <p>
            Uděláme Vám{' '}
            <a
              href='https://www.youtube.com/watch?v=NAjvzpBMS6s'
              target='_blank'
            >
              svatbu snů
            </a>
            , líbánky či oslavu výročí přesně podle Vašich představ.
          </p>
          <p>
            Vybereme s Vámi destinaci, hotel, svatební balíček a vyřídíme za Vás
            i všechny formality spojené s legalizací sňatku.
          </p>
          <p>Stačí, když nám do formuláře vyplníte Vaši základní představu.</p>
          <p>Ozveme se Vám a společně připravíme Váš velký den i líbánky.</p>
        </section>
        <WeddingForm />
      </v-container>
    );
  }
}
