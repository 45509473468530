import Vue from 'vue';
import { IS_MOBILE_BY_DEFAULT } from '~/../node_modules/elixir-theme/src/constants/MobileConstants';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { getLocalizedUrl } from '~/app/core/router';

import Header from '~/components/templates/Header';
import HeadManagement from '~/mixins/HeadManagement';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { DestinationModel } from '~/utils/destination';
import Destinations from '~/app/core/store/modules/Destinations';

import headerBg from '~/assets/images/header-bg-default.jpg';
import isSpecialOfferBackground, {
  backgrounds as specialOfferBackgrounds,
  SpecialOfferBackground,
} from '~/utils/specialOfferBackground';
import SpecialOffers from '~/app/core/store/modules/SpecialOffers';
import { createBigHotelBlock } from '~/utils/hotel';
import TailoredOfferForm from '~/components/templates/TailoredOfferForm';
import push from '~/utils/dataLayer';

function refIsLazyVueElement(data: any): data is Vue {
  return data && data instanceof Vue && data.$el !== null;
}
@Component
export default class extends Mixins<PrefetchComponent>(
  HeadManagement,
  Prefetch
) {
  protected isMobile: boolean = IS_MOBILE_BY_DEFAULT;

  protected get destinationsStore(): Destinations {
    return getModule(Destinations, this.$store);
  }

  protected get specialOffersStore(): SpecialOffers {
    return getModule(SpecialOffers, this.$store);
  }

  protected get destinations(): DestinationModel[] {
    return this.destinationsStore.destinations;
  }

  protected get sortedDestinations(): DestinationModel[] {
    const sortedDestinations: DestinationModel[] = [...this.destinations];

    sortedDestinations.sort((a, b) => {
      return (
        this.specialOffersStore.destinationRank[a.slug] -
        this.specialOffersStore.destinationRank[b.slug]
      );
    });

    return sortedDestinations;
  }

  public get seoMeta(): string {
    const metas: { name?: string; property?: string; content: string }[] = [];

    metas.push({
      name: 'title',
      content: this.$t('app.specialOffer.pageTitle').toString(),
    });
    metas.push({
      name: 'twitter:title',
      content: this.$t('app.specialOffer.pageTitle').toString(),
    });
    metas.push({
      property: 'og:title',
      content: this.$t('app.specialOffer.pageTitle').toString(),
    });

    metas.push({
      name: 'description',
      content: this.$t('app.specialOffer.description').toString(),
    });
    metas.push({
      name: 'twitter:description',
      content: this.$t('app.specialOffer.description').toString(),
    });
    metas.push({
      property: 'og:description',
      content: this.$t('app.specialOffer.description').toString(),
    });

    return metas
      .map((meta) => {
        return `<meta${meta.name ? ` name="${meta.name}"` : ''}${
          meta.property ? ` property="${meta.property}"` : ''
        } content="${meta.content}">`;
      })
      .join('');
  }

  public title() {
    return this.$t('app.specialOffer.pageTitle');
  }

  public prefetch() {
    return this.destinationsStore.loadDestinations().then(() => {
      return this.getDestinationHotels();
    });
  }

  public mounted() {
    push({
      page: {
        content_type: 'list',
        currency: 'CZK',
      },
    });
  }

  public render() {
    // Change colors based on the breakpoint
    const stripeColor =
      ['lg', 'xl'].indexOf(this.$vuetify.breakpoint.name.toString()) > -1
        ? 'rgba(255, 255, 255, 0.8)'
        : 'rgba(198, 197, 222, 0.9)';

    return (
      <div>
        <background-wrapper imgUrl={headerBg} class='header-bg-z-index'>
          <background-wrapper stripeColor={stripeColor}>
            <v-container class='px-0 pt-0'>
              <Header />
            </v-container>
          </background-wrapper>
        </background-wrapper>
        <v-container>
          <h1 class='my-3 accent--text text-center'>
            {this.$t('app.specialOffer.title')}
          </h1>
          {this.specialOffersStore.loading ? (
            <v-row>
              <v-col cols='12'>
                <h3 class='my-3 accent--text text-center'>
                  {this.$t('app.specialOffer.loading')}
                </h3>
              </v-col>
              {this.renderSkeletons()}
            </v-row>
          ) : (
            [
              <v-row class='justify-center'>{this.renderAnchors()}</v-row>,
              <v-row>{this.renderDestinations()}</v-row>,
            ]
          )}
        </v-container>
        <v-container>
          <v-row class='fill-height' justify='center' align='center'>
            <v-col>
              <TailoredOfferForm isMobile={this.isMobile} />
            </v-col>
          </v-row>
        </v-container>
      </div>
    );
  }

  @Watch('$vuetify.breakpoint.name')
  public setMobile() {
    this.isMobile =
      this.$vuetify.breakpoint.name === 'xs' ||
      this.$vuetify.breakpoint.name === 'sm' ||
      this.$vuetify.breakpoint.name === 'md';
  }

  protected getDestinationHotels() {
    return this.specialOffersStore.getDestinationOffers(this.destinations);
  }

  protected renderSkeletons() {
    return [...Array(6).keys()].map((index) => {
      return (
        <v-col cols='12' md='4' key={index}>
          <v-skeleton-loader type='card' />
        </v-col>
      );
    });
  }

  protected renderAnchors() {
    return this.sortedDestinations.map((destination) => {
      if (
        !this.specialOffersStore.destinationHotels.hasOwnProperty(
          destination.slug
        ) ||
        this.specialOffersStore.destinationHotels[destination.slug].length < 1
      ) {
        return;
      }
      return (
        <v-col cols='12' sm='auto' key={`${destination.id}__anchor`}>
          <v-btn
            block
            class='elix-button-text primary contrast--text'
            onClick={(e: Event) => {
              e.preventDefault();
              const ref = this.$refs.hasOwnProperty(destination.slug)
                ? this.$refs[destination.slug]
                : undefined;
              if (refIsLazyVueElement(ref)) {
                ref.$el.scrollIntoView({ behavior: 'smooth' });
              } else if (ref instanceof Element) {
                ref.scrollIntoView({ behavior: 'smooth' });
              }
            }}
          >
            {destination.name}
          </v-btn>
        </v-col>
      );
    });
  }

  protected renderDestinations() {
    return this.sortedDestinations.map((destination) => {
      const background: SpecialOfferBackground = isSpecialOfferBackground(
        destination.slug
      )
        ? specialOfferBackgrounds[destination.slug]
        : {
            img: headerBg,
            light: true,
          };
      if (
        !this.specialOffersStore.destinationHotels.hasOwnProperty(
          destination.slug
        ) ||
        this.specialOffersStore.destinationHotels[destination.slug].length < 1
      ) {
        return;
      }
      return (
        <v-col
          cols='12'
          ref={destination.slug}
          tag='section'
          key={`${destination.id}__section`}
        >
          <v-row class='justify-center'>
            <v-col cols='12' sm='6' md='5' lg='4'>
              <background-wrapper
                imgUrl={background.img}
                class='header-bg-z-index'
              >
                <h1
                  class={`py-6 text-center ${
                    background.light ? 'white--text' : 'primary--text'
                  }`}
                >
                  {destination.name}
                </h1>
              </background-wrapper>
            </v-col>
            <v-col cols='12'>
              {(() => {
                const data = this.specialOffersStore.destinationHotels[
                  destination.slug
                ].map((hotel) =>
                  createBigHotelBlock(hotel, this.$router, this.$i18n)
                );
                if (data.length < 1) {
                  return (
                    <h3 class='my-3 accent--text text-center'>
                      {this.$t('app.specialOffer.noOffers')}
                    </h3>
                  );
                }
                return (
                  <big-blocks-preview
                    blocks={data}
                    header=''
                    bottomButtonText={this.$t(
                      'app.specialOffer.destinationLink',
                      {
                        destination: destination.name,
                      }
                    )}
                    bottomButtonVuetifyClass='tertiary contrast--text'
                    onCenterButtonClick={() => {
                      this.$router.push({
                        path: getLocalizedUrl(destination.slug, this.$router),
                      });
                    }}
                  />
                );
              })()}
            </v-col>
          </v-row>
        </v-col>
      );
    });
  }
}
