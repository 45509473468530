declare global {
  interface Window {
    dataLayer: any[];
    gtag: any;
  }
}

export default function push(data: any) {
  if (typeof window === 'undefined' || !window) {
    return;
  }

  window.dataLayer.push(data);
}

export function pushEvent(
  category: string,
  interactive: boolean = false,
  action?: string,
  label?: string,
  value?: string
) {
  push({
    event: 'gaEvent',
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    eventValue: value,
    eventNonInteraction: !interactive,
  });
}
