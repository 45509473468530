import gql from 'graphql-tag';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { HotelList } from '~/components/templates/hotel';
import { HOTEL_LIST_FRAGMENT } from '~/components/templates/hotel/List';
import {
  AreasToGetArgs,
  AreaToHotelsArgs,
  DestinationsToGetArgs,
  DestinationToHotelsArgs,
  GQLRootQuery,
} from '~/GqlTypes';
import push from '~/utils/dataLayer';
import { format } from '~/utils/date-fns';
import { defaultGtmFormat } from '~/utils/dateTime';
import { HotelModel, createHotel } from '~/utils/hotel';
import { VueComponent } from '~/utils/vue-component';

const AREA_HOTELS_QUERY = gql`
  query($id: ID!, $after: String, $first: Int, $not: [ID!]) {
    areas {
      get(id: $id) {
        hotels(first: $first, after: $after, not: $not, visibility: PUBLISHED) {
          edges {
            node {
              ...HotelFragment
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
      }
    }
  }
  ${HOTEL_LIST_FRAGMENT}
`;

const DESTINATION_HOTELS_QUERY = gql`
  query($id: ID!, $after: String, $first: Int, $not: [ID!]) {
    destinations {
      get(id: $id) {
        hotels(first: $first, after: $after, not: $not, visibility: PUBLISHED) {
          edges {
            node {
              ...HotelFragment
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
      }
    }
  }
  ${HOTEL_LIST_FRAGMENT}
`;

interface RelatedHotels {
  hotel: HotelModel;
}

@Component
export default class extends VueComponent<RelatedHotels>
  implements RelatedHotels {
  @Prop({ required: true })
  public hotel!: HotelModel;

  /**
   * These are hotels that reside in the same area
   */
  protected areaHotels: HotelModel[] = [];

  /**
   * These are hotels that reside in the same destination, except for those in the same area
   */
  protected destinationHotels: HotelModel[] = [];

  /**
   * Number of hotels to load on button press
   */
  protected loadCount: number = 2;

  /**
   * Component state
   */
  protected loading: boolean = false;

  /**
   * State When more hotels are loading
   */
  protected fetchingMore: boolean = false;

  /**
   * Paging helper for the area hotels
   */
  protected areaCursor: string = '';

  /**
   * Paging helper for the destination hotels
   */
  protected destinationCursor: string = '';

  /**
   * Whether backend holds more hotels in the area
   */
  protected areaHasNextPage: boolean = true;

  /**
   * Whether backend holds more hotels in the destination
   */
  protected destinationHasNextPage: boolean = true;

  /**
   * Merges hotels, area first, destination second
   */
  protected get relatedHotels(): HotelModel[] {
    return this.areaHotels.concat(this.destinationHotels);
  }

  /**
   * Initially load first related hotels
   */
  public mounted() {
    if (!this.hotel) {
      return;
    }

    this.loading = true;

    this.loadHotels().finally(() => {
      this.loading = false;
    });
  }

  public render() {
    return (
      <HotelList
        hotels={this.relatedHotels}
        headline={this.$t('app.hotel.related')}
        fetchingMore={this.fetchingMore}
        loading={this.loading}
        buttonDisabled={!this.areaHasNextPage && !this.destinationHasNextPage}
        onLoadMore={this.loadHotels}
      />
    );
  }

  /**
   * When hotel changes, we need to reset the paging and initiate a new
   * load of related hotels
   */
  @Watch('hotel')
  protected hotelChanged() {
    this.areaHasNextPage = true;
    this.areaCursor = '';
    this.destinationHasNextPage = false;
    this.destinationCursor = '';
    this.loading = true;

    this.loadHotels().finally(() => {
      this.loading = false;
    });
  }

  /**
   * Main method to load related hotels.
   * Firstly it loads the hotels in the same area, if no more are found
   * it will attempt to load hotels from the same destination
   */
  protected loadHotels(): Promise<any> {
    if (!this.hotel.area) {
      return Promise.resolve();
    }
    this.fetchingMore = true;

    if (!this.areaHasNextPage && this.destinationHasNextPage) {
      // Fallback to the destination load if nothing more is in the area
      return this.loadDestinationHotels(this.loadCount).finally(() => {
        this.fetchingMore = false;
      });
    }

    // Prepare arguments for hotels connection
    const connectionArgs: AreaToHotelsArgs = {
      after: this.areaCursor ? this.areaCursor : undefined,
      first: this.loadCount,
      not: [this.hotel.id],
    };

    // Prepare arguments to query current area
    const areaGetArgs: AreasToGetArgs = {
      id: this.hotel.area.id,
    };

    return this.$apollo
      .query<GQLRootQuery>({
        query: AREA_HOTELS_QUERY,
        variables: {
          ...connectionArgs,
          ...areaGetArgs,
        },
      })
      .then((result) => {
        if (
          !result.data.areas.get ||
          result.data.areas.get.hotels.edges.length < 1
        ) {
          this.areaHasNextPage = false;
          this.areaCursor = '';
          // Lets check if there's anything under destination instead
          return this.maybeLoadDestinationHotels(0);
        }

        const hotelGtmData: unknown[] = [];

        // Push the fetched areas
        result.data.areas.get.hotels.edges.forEach((edge) => {
          const hotel = createHotel(edge.node);
          hotelGtmData.push({
            item_id: hotel.id,
            item_name: hotel.name,
            item_category: hotel.destination?.name,
            price: hotel.promoPackage?.price
              ? hotel.promoPackage.price * 2
              : undefined,
            nights: hotel.promoPackage?.numberOfNights,
            meals: hotel.promoPackage?.board || hotel.boardNames.join(', '),
            start_date:
              hotel.promoPackage?.from &&
              format(hotel.promoPackage.from, defaultGtmFormat),
            end_date:
              hotel.promoPackage?.to &&
              format(hotel.promoPackage.to, defaultGtmFormat),
            base_price: hotel.promoPackage?.price,
          });
          this.areaHotels.push(hotel);
        });

        if (hotelGtmData.length > 0) {
          push({
            event: 'view_item_list',
            ecommerce: {
              currency: 'CZK',
              item_list_name: `${this.hotel.name} - related hotels from area`,
              items: hotelGtmData,
            },
            _clear: true,
          });
        }

        // Save the current paging state
        const {
          endCursor,
          hasNextPage,
        } = result.data.areas.get.hotels.pageInfo;

        if (endCursor) {
          this.areaCursor = endCursor;
        }

        this.areaHasNextPage = hasNextPage;

        if (!this.areaHasNextPage) {
          // If this was the last page, maybe load more hotels from the destination
          return this.maybeLoadDestinationHotels(
            result.data.areas.get.hotels.edges.length
          );
        }
      })
      .finally(() => {
        this.fetchingMore = false;
      });
  }

  /**
   * Performed after area hotels are loaded. Fills the remaining loadCount with
   * hotels from destination if it wasn't fulfilled by area hotels
   *
   * If it was, it checks whether the destination holds more hotels than we
   * already have
   *
   * @param fetchedAreas
   */
  protected maybeLoadDestinationHotels(fetchedAreas: number): Promise<any> {
    if (!this.hotel.destination) {
      return Promise.resolve();
    }

    // Check the difference between desired count and currently fetched areas
    const diff = this.loadCount - fetchedAreas;
    if (diff > 0) {
      // If we need more hotels to fulfill the loadCount, just pass it to the load function
      return this.loadDestinationHotels(diff);
    }

    // Exclude hotels from the current area from query
    const connectionArgs: DestinationToHotelsArgs = {
      not: this.areaHotels.map((hotel) => hotel.id).concat(this.hotel.id),
    };

    // Build args to get the current destination
    const destinationGetArgs: DestinationsToGetArgs = {
      id: this.hotel.destination.id,
    };

    return this.$apollo
      .query<GQLRootQuery>({
        query: gql`
          query($id: ID!, $not: [ID!]) {
            destinations {
              get(id: $id) {
                hotels(first: 1, not: $not) {
                  totalCount
                }
              }
            }
          }
        `,
        variables: {
          ...connectionArgs,
          ...destinationGetArgs,
        },
      })
      .then((result) => {
        // Set the paging information so we know whether there are any more hotels on the destination
        this.destinationHasNextPage = !!(
          result.data.destinations.get &&
          result.data.destinations.get.hotels.totalCount > 0
        );
      });
  }

  /**
   * Loads hotels from the current destination
   * @param count
   */
  protected loadDestinationHotels(count: number) {
    if (!this.hotel.destination) {
      return Promise.resolve();
    }

    this.fetchingMore = true;

    // Build connection args for hotels, exclude all hotels loaded through area
    const connectionArgs: DestinationToHotelsArgs = {
      after: this.destinationCursor ? this.destinationCursor : undefined,
      first: count,
      not: this.areaHotels.map((hotel) => hotel.id).concat(this.hotel.id),
    };

    // Build args to get the current destination
    const destinationGetArgs: DestinationsToGetArgs = {
      id: this.hotel.destination.id,
    };

    return this.$apollo
      .query<GQLRootQuery>({
        query: DESTINATION_HOTELS_QUERY,
        variables: {
          ...connectionArgs,
          ...destinationGetArgs,
        },
      })
      .then((result) => {
        if (
          !result.data.destinations.get ||
          result.data.destinations.get.hotels.edges.length < 1
        ) {
          // It seems there is nothing more, reset the paging
          this.destinationHasNextPage = false;
          this.destinationCursor = '';
          return;
        }

        const hotelGtmData: unknown[] = [];

        // Push fetched hotels from the destination
        result.data.destinations.get.hotels.edges.forEach((edge) => {
          const hotel = createHotel(edge.node);
          hotelGtmData.push({
            item_id: hotel.id,
            item_name: hotel.name,
            item_category: hotel.destination?.name,
            price: hotel.promoPackage?.price
              ? hotel.promoPackage.price * 2
              : undefined,
            nights: hotel.promoPackage?.numberOfNights,
            meals: hotel.promoPackage?.board || hotel.boardNames.join(', '),
            start_date:
              hotel.promoPackage?.from &&
              format(hotel.promoPackage.from, defaultGtmFormat),
            end_date:
              hotel.promoPackage?.to &&
              format(hotel.promoPackage.to, defaultGtmFormat),
            base_price: hotel.promoPackage?.price,
          });
          this.destinationHotels.push(hotel);
        });

        if (hotelGtmData.length > 0) {
          push({
            event: 'view_item_list',
            ecommerce: {
              currency: 'CZK',
              item_list_name: `${this.hotel.name} - related hotels from destination`,
              items: hotelGtmData,
            },
            _clear: true,
          });
        }

        // Set the paging information
        const {
          endCursor,
          hasNextPage,
        } = result.data.destinations.get.hotels.pageInfo;

        if (endCursor) {
          this.destinationCursor = endCursor;
        }

        this.destinationHasNextPage = hasNextPage;
      });
  }
}
